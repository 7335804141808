@import "mixins";

.wrapper {
  max-height: min(60vh, 500px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--button-default-bg);
    border: 2px solid var(--card-bg-muted);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.selector {
  border: var(--border-width) solid var(--card-border);
  border-radius: var(--border-radius);
  overflow: hidden;

  img {
    height: 1rem;
  }
}

.accordion {
  @include flex-column(center, normal);
  width: var(--accordion-width);

  &:not(:last-child) {
    border-bottom: var(--border-width) solid var(--card-border);
  }

  .icon {
    outline: none;
    height: 0.8em;
    width: 0.8em;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0 20px;
    overflow-y: auto;
    max-height: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in, max-height 0.4s ease-in,
      padding-top 0.3s ease-in 0.05s, padding-bottom 0.4s ease 0.2s;
  }

  &.opened {
    .content {
      opacity: 1;
      max-height: 150px;
      border-top: var(--border-width) solid var(--card-border);
      padding: 10px 20px;

      &::-webkit-scrollbar {
        width: 12px;
        display: block;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background-color: var(--button-default-bg);
        border: 2px solid var(--card-bg-muted);
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  .item {
    @include flex(space-between);
    background: var(--card-bg);
    color: var(--text);
    padding: 14px 16px;
    cursor: pointer;
    font-weight: var(--normal);
    width: 100%;

    .icons_container {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 90%;
      font-size: var(--font-size);

      img {
        height: 18px;
        width: 18px;
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:not(:first-child) {
      border-top: var(--border-width) solid var(--card-border);
    }

    &:hover {
      background: var(--bg-muted);
      text-decoration: none;
    }
  }

  .network {
    @include flex(center);
    gap: 0.5rem;
    min-width: 4rem;
    border: var(--border-width) solid var(--card-border);
    border-radius: 1rem;
    padding: 4px 8px;
    color: var(--text);
    box-shadow: inset 0px 0px 0 0.5px var(--card-border);
    background-color: var(--bg-muted);
    font-size: 12px;
    font-weight: var(--bold);
  }
}

/* indicator */
.track {
  border: solid 1px var(--input-border);
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.indicator {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  &.checked {
    background: var(--text);
  }
}
