@import "mixins";

.select__asset {
  @include flex(flex-start);
  padding-bottom: var(--grid-gap);
  padding-inline: var(--main-padding-horizontal);
  gap: 0.6rem;
}

.moniker {
  @include truncate;
  color: var(--text);
  font-weight: var(--bold);
  max-width: 12rem;
}

.muted {
  color: var(--text-muted);
  font-size: 9px;
  font-weight: var(--bold);
  text-transform: uppercase;
}

.filter {
  padding-inline: var(--main-padding-horizontal);
}

.table {
  margin-block: 10px;
  td {
    background-color: var(--card-bg-muted) !important;
  }
}

.table__container {
  background-color: var(--card-bg-muted);
  padding: 20px var(--main-padding-horizontal);
  border-top: var(--border-width) solid var(--card-border);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  margin-top: 1rem;
}

.token__icon__container {
  position: relative;
  margin-right: 12px;

  .token__icon {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 50%;
    background: hsl(
      var(--button-default-bg-h),
      var(--button-default-bg-s),
      var(--button-default-bg-l)
    );
  }

  .chain__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
    padding: 1px;
    border-radius: 50%;
    background-color: var(--card-bg-muted);
  }
}

.alliance__logo {
  cursor: pointer;
  background-color: hsl(
    var(--button-default-bg-h),
    var(--button-default-bg-s),
    var(--button-default-bg-l)
  );
  color: var(--text-muted);
  font-size: small;
  padding: 0.1rem 0.6rem;
  border-radius: 0.8rem;
  margin-left: 0.4rem;
}
