@import "mixins";

.component {
  @include flex-column(space-between, stretch);
  background: var(--card-bg);
}

.inner {
  padding: 28px 20px;
}

.footer {
  @include flex;

  background: var(--bg);
  border-top: solid 1px var(--card-border);
  color: var(--text-muted);
  font-weight: var(--bold);
  line-height: 1;
  padding: 12px;
  transition: color var(--transition);

  &:hover {
    color: var(--text);
    text-decoration: none;
  }
}
