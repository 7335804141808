@import "mixins";

.assetlist {
  background-color: var(--card-bg-muted);
  padding-inline: 10px;
  grid-area: list;
  display: grid;
  grid-template-areas: "title" "list";
  grid-template-rows: min-content auto;
  overflow-y: hidden;
}

.assetlist__title {
  @include flex(space-between);
  margin: 25px 10px 10px;
  grid-area: title;
}

.manage__button {
  display: flex;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--button-primary-bg);
      stroke: var(--button-primary-bg);
    }
  }
}

.assetlist__list {
  overflow-y: auto;
  grid-area: list;
  padding-bottom: 1.6rem;
}
