@import "mixins";

.list {
  display: grid;
  gap: 8px;
  list-style: none !important;
  padding: 0 !important;

  button {
    background: var(--card-bg);
  }
}

.wallet {
  @include flex(space-between);
  width: 100%;
  padding: 12px 16px;
}

.header {
  margin-bottom: 8px;
  font-size: var(--font-size);
}

.active {
  background: var(--button-default-bg);
  border-color: var(--button-primary-bg);
}
