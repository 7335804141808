.card {
  margin: 20px;
}

.details__container {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
}

.alliance__info {
  li {
    list-style: inside;
  }
}
