@import "mixins";

.form {
  display: grid;
  max-height: calc(100vh - var(--header-height));
  min-height: calc(100vh - var(--header-height));
  grid-template-areas:
    "sendlist"
    "sendactions";
  grid-template-rows: auto min-content;
}

.send {
  grid-area: sendlist;
  background-color: var(--card-bg-muted);
  padding: 20px;
  margin-top: 0.2rem;
  padding-bottom: 40px;
  padding-top: 4.5rem;
  max-width: var(--wallet-width);
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
  h1 {
    font-size: 18pt;
  }
}

.actions {
  background-color: var(--card-bg);
  padding: 1rem;
  gap: 0.6rem;
  grid-area: sendactions;
  width: 100%;
  z-index: 1;
  border-top: var(--border-width) solid var(--card-border);

  input {
    margin-bottom: 16px;
  }

  button {
    width: 100%;
    padding-block: 20px;
    height: fit-content;
    margin-top: 0;
  }
}

.form__container {
  div + div {
    margin-top: 1.2rem;
  }
  h1 {
    margin-top: 1.45rem;
    margin-bottom: 1.45rem;
    margin-left: 3rem;
  }
}

.form__header__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--card-bg-muted);
  z-index: 1;
}

.destination {
  color: var(--button-primary-bg);
}

.destination__error {
  color: var(--danger);
}

.icon {
  font-size: 16px;
}
