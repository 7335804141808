.back {
  position: absolute;
  left: 40px;
  top: 46px;
}
.extra {
  position: absolute;
  right: 40px;
  top: 40px;
}

.button__container {
  width: 100%;
}

.advanced {
  margin-top: 30px;
}
