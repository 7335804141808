@import "mixins";

.receive {
  background-color: var(--card-bg-muted);
  padding: 20px;
  padding-top: 1.5rem;
  grid-area: list;
  max-width: var(--wallet-width);
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
  position: relative;

  h1 {
    font-size: 18pt;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  p {
    color: var(--text-muted);
    margin-top: 1rem;
    margin-bottom: 0.6rem;
  }
}

.chain__selector {
  @include flex(flex-start);
  gap: 10px;

  button {
    font-weight: var(--normal);
    background-color: var(--button-default-bg);
    padding: 4px 15px;
    border-radius: 20px;
  }

  .active {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
  }
}

.address__table {
  max-height: 655px;
  align-items: start;
}
