@use "sass:math";
@import "mixins";

@mixin size($min-width, $height) {
  //border-radius: math.div($height, 2);
  min-width: $min-width;
  height: $height;
}

.button {
  @include inline-flex;
  @include size(150px, 48px);

  border-radius: 15px;
  font-weight: var(--bold);
  gap: 5px;
  line-height: 1;
  padding-left: 20px;
  padding-right: 20px;
  white-space: pre;

  &:hover {
    text-decoration: none;
  }
}

.disabled,
.loading {
  @include disabled;
}

/* size */
.small {
  @include size(100px, 32px);
  font-size: 14px;
}

/* color */
@mixin variant($bg-h, $bg-s, $bg-l, $color, $type) {
  background: hsl($bg-h, $bg-s, $bg-l);
  color: $color;
  transition: background var(--transition);

  @if $type != default {
    &:hover:not(:disabled) {
      background: hsl($bg-h, $bg-s, calc($bg-l - 5%));
    }
  } @else {
    &:hover:not(:disabled) {
      background: var(
        --dark-button-hover-only,
        hsl($bg-h, $bg-s, calc($bg-l - 5%))
      );
    }
  }
}

$colors: (
  default: (
    bg-h: var(--button-default-bg-h),
    bg-s: var(--button-default-bg-s),
    bg-l: var(--button-default-bg-l),
    color: var(--button-default-text),
  ),
  primary: (
    bg-h: var(--button-primary-bg-h),
    bg-s: var(--button-primary-bg-s),
    bg-l: var(--button-primary-bg-l),
    color: var(--button-primary-text),
  ),
  danger: (
    bg-h: var(--button-danger-bg-h),
    bg-s: var(--button-danger-bg-s),
    bg-l: var(--button-danger-bg-l),
    color: var(--button-primary-text),
  ),
);

@each $color, $value in $colors {
  .#{$color} {
    @include variant(
      map-get($value, bg-h),
      map-get($value, bg-s),
      map-get($value, bg-l),
      map-get($value, color),
      $type: $color
    );
  }
}

/* outline */
.outline {
  border: var(--border-width) solid currentColor;
  color: currentColor;
}

/* block */
.block {
  width: 100%;
}
