.aside_background {
  position: absolute;
  top: 20%;

  @media screen and (max-height: 1100px) {
    top: 10%;
  }

  @media screen and (max-height: 900px) {
    top: 0%;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
}
