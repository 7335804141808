.text {
  padding: 4px;
  text-align: center;
  a {
    color: white;
    text-decoration: underline;
  }
}

.info {
  background: var(--info);
  color: white;
}
