// @font-face {
//   src: url("fonts/Gotham-Thin_Web.woff2") format("woff2");
//   font-family: "Gotham";
//   font-weight: 100;
// }

@font-face {
  src: url("fonts/Gotham-XLight_Web.woff2") format("woff2");
  font-family: "Gotham";
  font-weight: 100;
}

@font-face {
  src: url("fonts/Gotham-Light_Web.woff2") format("woff2");
  font-family: "Gotham";
  font-weight: 200;
}

@font-face {
  src: url("fonts/Gotham-Book_Web.woff2") format("woff2");
  font-family: "Gotham";
  font-weight: 300;
}

@font-face {
  src: url("fonts/Gotham-Medium_Web.woff2") format("woff2");
  font-family: "Gotham";
  font-weight: 400;
}

@font-face {
  src: url("fonts/Gotham-Bold_Web.woff2") format("woff2");
  font-family: "Gotham";
  font-weight: 500;
}

@font-face {
  src: url("fonts/Gotham-Black_Web.woff2") format("woff2");
  font-family: "Gotham";
  font-weight: 800;
}

@font-face {
  src: url("fonts/Gotham-Ultra_Web.woff2") format("woff2");
  font-family: "Gotham";
  font-weight: 900;
}
