@import "mixins";
@import "forms";

.wrapper {
  @include border;
  @include size;
  max-width: 100%;
  @include flex(space-between);
  gap: 8px;
}

.btnWrapper {
  @include flex;
  gap: 8px;
}
