@import "mixins";

.container {
  position: relative;
  @include flex;
  height: 700px;
  width: 450px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.iframe {
  border: none;
  width: 100%;
  height: 100%;
}
