@import "mixins";

.header {
  @include flex(space-between);
  border-bottom: var(--border-width) solid var(--menu-border);
  margin-bottom: 26px;
  z-index: 1;
}

/* mobile */
.toggle {
  color: var(--menu-text);
  padding: 20px;

  @include desktop {
    display: none;
  }
}

/* menu item */
.item {
  color: var(--menu-text);
  fill: var(--menu-text);
  z-index: 1;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

.logo {
  flex: 1;
  display: block;
  font-size: 18px;
  padding: 0 20px;
  line-height: var(--header-height);
  color: var(--menu-text);

  strong {
    font-weight: var(--bold);
  }

  img {
    height: 24px;
    margin-right: 4px;
  }
}

.title {
  @media (max-width: 500px) {
    display: none;
  }
}

.link {
  @include flex(flex-start);

  gap: 10px;
  opacity: 0.75;
  padding: 14px 20px;
  transition: opacity var(--transition);

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    font-weight: var(--bold);
  }

  @include mobile {
    font-size: 28px;
    padding: 6px 20px;

    svg {
      display: none;
    }
  }
}

.background_blur_blossom,
.background_blur_blossom2,
.background_blur_blossom3 {
  background-color: #ec22c0;
  position: absolute;
  filter: blur(95px);
  z-index: 1;

  @include mobile {
    display: none;
  }

  &.open {
    display: block;
  }

  @media (max-height: 501px) {
    display: none !important;
  }
}

.background_blur_blossom {
  height: 200px;
  width: 700px;
  left: -275px;
  bottom: 225px;
  rotate: 65deg;
  filter: blur(95px);
  transition: all 0.5s ease-in-out;

  &.open {
    height: 400px;
    filter: blur(150px);
    left: -200px;
    bottom: 0;
    width: 1000px;
    rotate: 45deg;
  }

  @media (max-height: 1000px) {
    width: 400px;
    right: -200px;
    left: auto;
    bottom: 0;

    &.open {
      width: 600px;
      left: 200px;
      bottom: -50px;
      height: 200px;
      rotate: 20deg;
      filter: blur(90px);
    }
  }

  @media (max-height: 700px) {
    width: 150px;
    height: 100px;
    right: -15px;
    left: auto;
    bottom: -15px;
    rotate: 0deg;
    filter: blur(35px);
  }
}

.background_blur_blossom2 {
  height: 100px;
  width: 100px;
  bottom: -15px;
  left: -15px;
  filter: blur(35px);

  @media (max-height: 700px) {
    height: 150px !important;
  }

  &.open {
    height: 250px;
    width: 250px;
    left: -50px;
    bottom: -50px;
    filter: blur(60px);
  }
}

.background_blur_blossom3 {
  height: 150px;
  width: 200px;
  right: -50px;
  bottom: 10px;
  rotate: 50deg;
}
