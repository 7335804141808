@import "mixins";

.component {
  @include desktop {
    @include flex(flex-start);
    gap: 12px;
  }
}

.message {
  font-size: 14px;
  color: var(--text-muted);
}
