.main {
  overflow: hidden;
  width: 100%;
}

.card {
  header {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 8px;

    h1 {
      width: auto;
    }
  }
}

.link {
  font-weight: var(--normal);
  min-width: 150px;
  justify-content: flex-start;
}
