@import "mixins";

.staked__container {
  display: grid;
  grid-gap: var(--grid-gap);
  gap: var(--grid-gap);
  min-width: 275px;
  flex: 1 1 0%;

  @include mobile {
    grid-auto-flow: column;
    min-width: unset;
  }

  @media (max-width: 500px) {
    grid-auto-flow: row;
  }
}
