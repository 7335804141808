@import "mixins";

.overlay {
  @include flex;
  background: hsl(0 0% 0% / 0.8);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 1050;
}

.modal {
  max-height: calc(100% - 40px);
  display: grid;
  gap: 32px;

  background: var(--card-bg-muted);
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--card-border);
  box-shadow: var(--box-shadow);
  outline: 0;
  position: relative;
  //overflow: hidden;

  padding: 40px 30px;
  width: var(--modal-width);

  @media (max-width: 600px) {
    padding: 48px 20px;
    width: calc(100% - 40px);
  }

  &.minimal {
    width: fit-content !important;
    padding: 0 !important;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  padding: 16px;
}

.header {
  text-align: center;
}

.icon {
  font-size: 56px;
  line-height: 1;
}

.title {
  font-size: 20px;
}

.confirm {
  font-size: inherit;
}

.footer {
  margin-top: 8px;
}

.main {
  overflow-y: scroll;
  max-height: 70vh;
}
