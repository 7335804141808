@import "mixins";

.button {
  @include inline-flex;
  color: var(--text);
  font-size: 10px;
  gap: 2px;
  height: 20px;
  padding: 0 10px;
  text-transform: uppercase;
}
