@import "mixins";

.address__button {
  @include flex(space-between);
  background: var(--card-bg);
  border: var(--border-width) solid var(--card-border);
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 12px;
  font-weight: var(--normal);

  &:hover {
    background: var(--card-bg-muted);
  }

  span {
    @include flex(flex-start);
    gap: 10px;
    font-weight: var(--bold);
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.address__table {
  max-height: min(80vh, 300px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--button-default-bg);
    border-radius: 16px;
    border: 2px solid var(--card-bg-muted);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
